.App {
  text-align: center;
}

.App-header {
  background-color: #f2fcff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.logo {
  width: 200px;
  height: 200px;
}
.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Site-footer {
  text-align: center;
  font-size: 0.7em;
  color: gray;
  margin-bottom: 1em;
}

.bg-light {
  margin-left: 2em;
  margin-right: 2em;
}

.google-play-btn {
  width: 200px;
}
